<template>
  <b-card
    title="Melhor Envio"
    class="top-level p-1"
  >
    <b-row align-v="center">
      <b-col
        v-if="!$store.state.shipping.loading"
        md="4"
      >
        <b-button
          v-if="!$store.state.shipping.account || new Date($store.state.shipping.account.expires_at) < new Date()"
          class=""
          variant="success"
          @click.stop="conectar()"
        >Conectar sua conta do Melhor Envio</b-button>
        <div v-else>
          Sua conta do Melhor Envio está conectada
          <b-button
            class="m-1"
            variant="danger"
            @click.stop="desconectar()"
          >Desconectar</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row align-v="center">
      <b-col md="4">
        <b-form-group>
          <label>CEP de envio dos fretes</label>
          <b-form-input
            id="name"
            v-model="CEP_ENVIO_FRETE.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-button
          class="float-left mt-1"
          variant="success"
          @click.stop="saveKey(CEP_ENVIO_FRETE)"
        >
          Gravar
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      CEP_ENVIO_FRETE: {
        key: 'CEP_ENVIO_FRETE',
        value: '',
      },
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.$store.dispatch('shipping/refreshTokenMelhorEnvio')
    this.$store.dispatch('configurations/fetchConfiguration', 'CEP_ENVIO_FRETE').then(result => {
      this.CEP_ENVIO_FRETE = result
    })
  },
  methods: {
    conectar() {
      this.$store.dispatch('shipping/getConnectionURLMelhorEnvio').then(result => {
        window.open(result.data.connection_url, '_blank')
      })
    },
    desconectar() {
      this.$store.dispatch('shipping/disconnectMelhorEnvio').then(() => {
        this.$store.dispatch('shipping/refreshTokenMelhorEnvio')
      })
    },
    saveKey(keyToSave) {
      this.$store.dispatch('configurations/updateConfiguration', keyToSave).then(result => {
        keyToSave = result
        this.$bvToast.toast('Gravado com sucesso!', {
          title: 'Sucesso',
          variant: 'success',
          solid: true,
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.top-level {
  background: lightgrey;
}
</style>
